/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    radioactive: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a7 7 0 100 14A7 7 0 008 1M0 8a8 8 0 1116 0A8 8 0 010 8"/><path pid="1" d="M9.653 5.496A3 3 0 008 5c-.61 0-1.179.183-1.653.496L4.694 2.992A5.97 5.97 0 018 2c1.222 0 2.358.365 3.306.992zm1.342 2.324a3 3 0 01-.884 2.312 3 3 0 01-.769.552l1.342 2.683c.57-.286 1.09-.66 1.538-1.103a6 6 0 001.767-4.624zm-5.679 5.548l1.342-2.684A3 3 0 015.005 7.82l-2.994-.18a6 6 0 003.306 5.728zM10 8a2 2 0 11-4 0 2 2 0 014 0"/>',
    },
});
